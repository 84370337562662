import * as React from 'react'
import Layout from '../components/layout'
import ContentMargins from '../components/ContentMargins'
import {css} from '@emotion/core'
import { ContactForm } from '../components/ContactForm'
import { Link } from '../components/Link'


export const OpeningHoursPage = () => (
  <Layout css={css`
    font-size: 20px;
  `}>
    <ContentMargins style={{marginBottom: 40}}>
      <h1 style={{ fontSize: '40px', fontWeight: 900}}>
        Unsere Öffnungszeiten.
      </h1>

      <p>
        Wir haben <strong>sechs Tage in der Woche</strong>, also auch am <strong>Samstag</strong> und <strong>an fast allen Feiertagen</strong> geöffnet.
      </p>
      <div style={{margin: '10px 40px'}}>
        Montag bis Freitag, 08:30 bis 17:00 Uhr<br />
        Samstag, 08:30 bis 15:00 Uhr
      </div>
      <p>
        Außerhalb der Öffnungszeiten können Sie uns gerne <a href={"mailto:blumen@elsdoerfer.com"}>per Email</a> oder
        über <Link to={"/kontakt"}>das Kontaktformular</Link> kontaktieren und wir kümmern uns schnellst möglichst um
        Ihr Anliegen.
      </p>
    </ContentMargins>
  </Layout>
)

export default OpeningHoursPage;
